import gql from 'graphql-tag';

const queryChartNewAlbumRankPagination = gql`
  query chartNewAlbumRankPagination($num: Int, $cursor: String) {
    chartNewAlbumRank(first: $num, after: $cursor) {
      totalCount
      name
      edges {
        cursor
        node {
          name
          albumId
          imageUrl
          artist {
            ...ArtistFragment
          }
        }
      }
    }
  }
`;

export default queryChartNewAlbumRankPagination;
