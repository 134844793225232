import { Box, Text, Skeleton } from '@chakra-ui/react';
import Link from 'next/link';
import { MomentCover } from './MomentCover';

const headTitleProps = {
  color: '#07B53B',
  textAlign: 'left',
  fontSize: {
    base: '14px',
    x2l: '17px',
    x3l: '20px',
  },
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: {
    base: '15px',
    x2l: '20px',
    x3l: '24px',
  },
  minH: {
    base: '15px',
    x2l: '20px',
    x3l: '24px',
  },
} as const;

const titleProps = {
  textAlign: 'left',
  pt: { base: '4px', lg: '9px' },
  className: 'ellipsis_text one_line',
  color: '#000000',
  fontSize: {
    base: '19px',
    xl: '24px',
    x2l: '31px',
    x3l: '36px',
  },
  lineHeight: {
    base: '22px',
    xl: '28px',
    x2l: '37px',
    x3l: '43px',
  },
  minH: {
    base: '26px',
    lg: '31px',
    xl: '37px',
    x2l: '46px',
    x3l: '52px',
  },
  fontWeight: 700,
} as const;

const descriptionProps = {
  color: '#777777',
  pt: { base: '4px', lg: '9px' },
  fontSize: {
    base: '14px',
    x2l: '17px',
    x3l: '20px',
  },
  lineHeight: {
    base: '15px',
    x2l: '20px',
    x3l: '24px',
  },
  minH: {
    base: '19px',
    lg: '24px',
    x2l: '29px',
    x3l: '33px',
  },
  fontWeight: 500,
} as const;

interface IMomentInfo {
  href?: string;
  headTitle?: string;
  title?: string;
  description?: string;
  iconImageUrl?: string;
}

export function MomentInfo({
  href,
  headTitle,
  title,
  description,
  iconImageUrl,
}: IMomentInfo) {
  return (
    <Link href={href || ''}>
      <Box
        _focusVisible={{ outline: 'none' }}
        m={{ base: '0px 4px 4px 4px', lg: '0px 9px 9px 9px' }}
      >
        <Skeleton
          isLoaded={href !== undefined}
          fadeDuration={1}
          {...headTitleProps}
          pt={{ base: '4px' }}
          mb="4px"
          h={{
            base: '18px',
            md: '23px',
          }}
          display={{ base: 'block', lg: 'none' }}
          textAlign="initial"
        >
          {headTitle}
        </Skeleton>
        <MomentCover iconImageUrl={iconImageUrl} />
        <Skeleton
          isLoaded={href !== undefined}
          fadeDuration={1}
          textAlign="initial"
          mt={{ base: '4px', lg: '9px' }}
        >
          <Text {...headTitleProps} display={{ base: 'none', lg: 'block' }}>
            {headTitle}
          </Text>
          <Text {...titleProps}>{title}</Text>
          <Text className="ellipsis_text one_line" {...descriptionProps}>
            {description}
          </Text>
        </Skeleton>
      </Box>
    </Link>
  );
}
