import { Image, Skeleton } from '@chakra-ui/react';
import { useState } from 'react';

interface IMomentCoverProps {
  iconImageUrl?: string;
}

export function MomentCover({ iconImageUrl }: IMomentCoverProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Skeleton
      isLoaded={iconImageUrl !== undefined && isLoaded}
      aspectRatio="240 / 115"
      fadeDuration={1}
    >
      <Image
        loading="eager"
        src={iconImageUrl}
        alt=""
        margin="0 auto"
        onLoad={() => setIsLoaded(true)}
      />
    </Skeleton>
  );
}
