import { Image, useStyleConfig } from '@chakra-ui/react';

export type IsotoxalFigure = {
  size:
    | 'thumbnailSmall'
    | 'thumbnail'
    | 'default'
    | 'resizeWithScreen'
    | 'deformable'
    | 'endPagePreview'
    | 'description'
    | 'magazineAlbumCover'
    | 'magazineImage';
  variant?: 'circle';
};

type Style = IsotoxalFigure;

export type LoadingPicType = 'album' | 'artist' | 'other' | 'playlist';

interface IPictureProps<S> {
  iconImageUrl?: string;
  title: string;
  type: LoadingPicType;
  style: S;
}

interface styleObject {
  width: string;
  borderRadius: string;
  boxSize: string;
}

export function Picture<S extends Style>({
  iconImageUrl,
  title,
  type,
  style,
}: IPictureProps<S>) {
  const styles = useStyleConfig('Image', style) as styleObject;

  return (
    <Image
      loading="lazy"
      src={iconImageUrl}
      alt={title}
      {...styles}
      pos="relative"
      bgImage={`url(/DefaultPic/${type}.png)`}
      bgSize="100%"
      _after={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgImage: `url(/DefaultPic/${type}.png)`,
        bgSize: '100%',
      }}
    />
  );
}
