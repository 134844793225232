import gql from 'graphql-tag';

const queryHopeYouLikePagination = gql`
  query hopeYouLikePlaylistPagination($num: Int, $cursor: String) {
    hopeYouLikePlaylist(first: $num, after: $cursor) {
      totalCount
      edges {
        cursor
        node {
          playlistId
          title
          subTitle
          description
          imageUrl
        }
      }
    }
  }
`;

export default queryHopeYouLikePagination;
