import { getApolloClient, MainPageCache } from '@/libs/apollo-client';
import queryMainPage from '@/graphql/schema/query/queryMainPage';
import { MainPageQuery } from '@/graphql/generated/schema';
import { GetServerSideProps } from 'next/types';
import { Box } from '@chakra-ui/react';
import {
  convertAlbumNodeData,
  convertPlaylistNodeData,
  FetchMoreCarouselSection,
} from '@/components/Common/SeeMoreSection/Carousel';
import { Header } from '@/components/Common/Header';
import { MemoCommonPreview } from '@/components/Common/Preview/CommonPreview';
import { RankChartMemo } from '@/components/Common/SeeMoreSection/Rank';
import useChartCollection from '@/hooks/useChartCollection';
import queryHopeYouLikePagination from '@/graphql/schema/query/queryHopeYouLikePagination';
import queryMagazinesPagination from '@/graphql/schema/query/queryMagazinesPagination';
import queryChartNewAlbumRankPagination from '@/graphql/schema/query/queryChartNewAlbumRankPagination';
import {
  convertMagazineNodeData,
  HotSingersMemo,
  MomentMemo,
} from '@/components/Page/MainPage';
import { usePlayerMediator } from '@/hooks/usePlayerMediator';
import { MemoMagazinePreview } from '@/components/Page/MainPage/MagazinePreview';

interface IHomeProps {
  cache: MainPageCache;
  secondChartTitle: 'globalRank' | 'chineseRank' | 'japanRank' | 'koreanRank';
  data: {
    latestRank: MainPageQuery['latestRank'];
    secondRank: MainPageQuery['secondRank'];
  };
}

function generateHeaderData(data: MainPageQuery['latestRank']) {
  const top10Tracks =
    data?.edges[0]?.node?.tracks
      ?.map(({ trackInfo }) => trackInfo.trackTitle)
      .join(',') || '';

  const top10Artists =
    data?.edges[0]?.node?.tracks
      ?.map(({ trackInfo }) => trackInfo.artist?.artistName)
      .join(',') || '';

  const title =
    'LINE MUSIC - LINE鈴聲、來電答鈴、背景音樂、音樂焦點、音樂排行榜、最新專輯等豐富的音樂內容';

  return {
    data: {
      tabTitle: title,
      title,
      'og:title': 'LINE MUSIC給你最新、最受歡迎的流行音樂！',
      description: `最新、最受歡迎的流行音樂都在LINE MUSIC - ${top10Tracks}`,
      'og:description': 'LINE MUSIC給你最新、最受歡迎的流行音樂！',
      keywords: `${top10Tracks}, ${top10Artists}`,
      path: '',
    },
  };
}
const config = [
  { subjectId: 'latestRank', index: 0 },
  { subjectId: 'hotSingers', index: 1 },
  { subjectId: '', index: 2 },
];

function Home({ cache, data, secondChartTitle }: IHomeProps) {
  getApolloClient(cache);
  usePlayerMediator();
  const index = useChartCollection(config);

  return (
    <>
      <Header {...generateHeaderData(data.latestRank)} />
      <MomentMemo />
      <Box
        w={{ x3l: '964px' }}
        m={{ x3l: '0 auto', md: '0 43px', base: '0 20px' }}
        mb={{ md: '50px', base: '20px' }}
      >
        <RankChartMemo
          data={data.latestRank?.edges[0].node}
          chartName="latestRank"
          isNotFirstChart={true}
        />
        {index >= 1 && (
          <>
            <RankChartMemo
              data={data.secondRank?.edges[0].node}
              chartName={secondChartTitle}
              isNotFirstChart={true}
            />
            <HotSingersMemo />
          </>
        )}
        {index >= 2 && (
          <>
            <FetchMoreCarouselSection
              id="hopeYouLikePlaylist"
              queryDoc={queryHopeYouLikePagination}
              sectionTitle="編輯推薦鈴聲"
              href={`/list/related-playlists?from=recommendPlaylist&refererName=${encodeURIComponent(
                '編輯精選鈴聲歌單'
              )}`}
              converter={convertPlaylistNodeData}
              Component={MemoCommonPreview}
            />
            <FetchMoreCarouselSection
              id="magazines"
              queryDoc={queryMagazinesPagination}
              sectionTitle="音樂焦點"
              href="/magazine"
              converter={convertMagazineNodeData}
              Component={MemoMagazinePreview}
            />
            <FetchMoreCarouselSection
              id="chartNewAlbumRank"
              queryDoc={queryChartNewAlbumRankPagination}
              sectionTitle="最新專輯"
              href="/new-albums"
              converter={convertAlbumNodeData}
              Component={MemoCommonPreview}
            />
          </>
        )}
      </Box>
    </>
  );
}

function getDateNum() {
  const now = new Date();

  const start = new Date(now.getFullYear(), 0, 0);

  const diff = now.valueOf() - start.valueOf();

  const oneDay = 1000 * 60 * 60 * 24;

  const day = Math.floor(diff / oneDay);

  return day % 4;
}

export const getServerSideProps: GetServerSideProps<IHomeProps> = async ({
  res,
}) => {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=600, stale-while-revalidate=60'
  );

  const chartTypeMap = [
    { typeId: 3, name: 'globalRank' },
    { typeId: 1, name: 'chineseRank' },
    { typeId: 4, name: 'japanRank' },
    { typeId: 5, name: 'koreanRank' },
  ] as const;

  const index = getDateNum();

  const aplClient = getApolloClient();

  const { data } = await aplClient.query<MainPageQuery>({
    query: queryMainPage,
    variables: { typeId: chartTypeMap[index].typeId },
    errorPolicy: 'ignore',
  });

  return {
    props: {
      cache: {
        ROOT_QUERY: {
          magazines: data.magazines,
          hopeYouLikePlaylist: data.hopeYouLikePlaylist,
          chartNewAlbumRank: data.chartNewAlbumRank,
          __typename: 'Query',
        },
      },
      secondChartTitle: chartTypeMap[index].name,
      data: {
        latestRank: data.latestRank,
        secondRank: data.secondRank,
      },
    },
  };
};

export default Home;
