import { Picture } from '@/components/Common/Picture';
import { IsotoxalFigure } from '@/components/Common/Picture/Picture';
import { memo, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Link from 'next/link';

function generateDate() {
  const date = new Date();

  const m = date.getMonth() + 1;

  const mStr = m < 10 ? `0${m}` : `${m}`;

  const d = date.getDate();

  const dStr = d < 10 ? `0${d}` : `${d}`;

  return `${date.getFullYear()}.${mStr}.${dStr}`;
}

const titleProps = {
  textAlign: 'left',
  maxH: { md: '34px', base: '20px' },
  lineHeight: { md: '17px', base: '20px' },
  fontSize: { md: '14px', base: '17px' },
  mt: '10px',
  color: 'black',
} as const;

const dateProps = {
  fontSize: '13px',
  lineHeight: '17px',
  textAlign: 'left',
  marginTop: '4px',
} as const;

interface IMagazinePreviewProps {
  node: {
    title: string;
    magazineId: number;
    iconImageUrl: string;
    picStyle: IsotoxalFigure;
    startDate: string;
  };
}

export function convertMagazineNodeData(node: IMagazinePreviewProps) {
  return node;
}

function MagazinePreview({ node }: IMagazinePreviewProps) {
  const currentDate = useRef(generateDate());

  return (
    <Flex direction="column" key={node.title}>
      <Link href={`/magazine/${node.magazineId}`}>
        <Picture<IsotoxalFigure>
          iconImageUrl={node.iconImageUrl}
          title={node.title}
          type="playlist"
          style={{ size: 'default' as const }}
        />
        <Box
          className="ellipsis_text"
          {...titleProps}
          sx={{ WebkitLineClamp: { md: 2, base: 1 } }}
        >
          {node.title}
        </Box>
        <Box {...dateProps}>
          <Box as="span" color="#999">
            {node.startDate}
          </Box>
          {currentDate.current === node.startDate && (
            <Box as="span" marginLeft="7px" color="#ff1150">
              New
            </Box>
          )}
        </Box>
      </Link>
    </Flex>
  );
}

export const MemoMagazinePreview = memo(
  MagazinePreview,
  (prev, next) => prev.node.magazineId === next.node.magazineId
);
