import { useEffect, useState } from 'react';
import { getAxiosInstance } from '@/libs/axios';
import { captureException } from '@sentry/nextjs';
import getConfig from 'next/config';

type MomentResp = {
  description: string;
  headTitle: string;
  title: string;
  image: {
    baseImageUrl: string;
  };
  content: { [key: string]: { [key: string]: string } };
  type: string;
};

type MomentJson = {
  description?: string;
  headTitle?: string;
  title?: string;
  iconImageUrl?: string;
  href?: string;
};

interface ImomentDataType {
  [type: string]: {
    object: string;
    id: string;
  };
}
const momentDataType: ImomentDataType = {
  LIVE: {
    object: 'live',
    id: 'urlScheme',
  },
  ALBUM: {
    object: 'album',
    id: 'albumId',
  },
  MAGAZINE: {
    object: 'magazine',
    id: 'magazineId',
  },
  TRACK: {
    object: 'track',
    id: 'trackId',
  },

  PLAYLIST: {
    object: 'playlist',
    id: 'plId',
  },
};

function appendLinkUrl(data: MomentResp[]) {
  const { publicRuntimeConfig } = getConfig();

  return data.reduce((result, item) => {
    const transferType = momentDataType[item.type];

    if (transferType !== undefined) {
      const contentObject = item.content[transferType.object];

      let href;

      if (transferType.object === 'album') {
        href = `/album/${contentObject[transferType.id]}`;
      } else if (transferType.object !== 'live') {
        href = `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/${
          transferType.object
        }/${contentObject[transferType.id]}`;
      } else {
        href = `${contentObject[transferType.id]}`;
      }
      result.push({
        description: item.description,
        headTitle: item.headTitle,
        title: item.title,
        iconImageUrl: item.image.baseImageUrl,
        href,
      });
    }

    return result;
  }, [] as MomentJson[]);
}

interface MomentStatus {
  isLoaded: boolean;
  data: MomentJson[];
}

const initData = {};

function useMoment() {
  const [moment, updateMoment] = useState<MomentStatus>({
    data: [initData, initData, initData],
    isLoaded: false,
  });

  useEffect(() => {
    const fetchMomentData = async () => {
      try {
        const { data } = await getAxiosInstance().get(
          `/api/today/v1/billboards`
        );

        const reformData = appendLinkUrl(data.response.result.billboards);

        updateMoment({ data: reformData, isLoaded: true });
      } catch (err) {
        captureException(err);
      }
    };

    fetchMomentData();
  }, []);

  return { moment };
}

export { useMoment };
