import { Button, Box } from '@chakra-ui/react';
import { CustomArrowProps } from 'react-slick';

const iconProps = {
  width: {
    lg: '20px',
    xl: '30px',
    x3l: '36px',
  },
  height: {
    lg: '40px',
    xl: '60px',
    x3l: '72px',
  },
  bgSize: {
    lg: '20px 40px',
    xl: '30px 60px',
    x3l: '36px 72px',
  },
};

const buttonProps = {
  height: 'auto',
  position: 'absolute',
  zIndex: 1,
  display: { base: 'none', lg: 'block' },
  top: 'calc(45%)',
  transform: 'translateY(-50%)',
  bg: 'transparent',
  _hover: {
    bg: 'transparent',
  },
} as const;

export function PrevArrowButton({ onClick }: CustomArrowProps) {
  return (
    <Button onClick={onClick} {...buttonProps} left="0px">
      <Box bg="url(/ScrollButton/MomentPrev.svg) no-repeat" {...iconProps} />
    </Button>
  );
}

export function NextArrowButton({ onClick }: CustomArrowProps) {
  return (
    <Button onClick={onClick} {...buttonProps} right="0px">
      <Box bg="url(/ScrollButton/MomentNext.svg) no-repeat" {...iconProps} />
    </Button>
  );
}
