import BaseCarousel from '@/components/Common/BaseCarousel';
import { SectionLayout } from '@/components/Common/Layout/SectionLayout';
import { CommonPreview } from '@/components/Common/Preview/CommonPreview';
import { getAxiosInstance } from '@/libs/axios';
import {
  Flex,
  SkeletonCircle,
  SkeletonText,
  useStyleConfig,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

type Artist = {
  artistId: number;
  name: string;
  imageUrl: string;
};

function LoadingPreivew() {
  const styles = useStyleConfig('Image', {
    size: 'default',
  }) as { boxSize: string };

  return (
    <Flex direction="column">
      <SkeletonCircle m="0px auto" {...styles} />
      <SkeletonText
        m="10px auto"
        noOfLines={1}
        skeletonHeight={{ md: '17px', base: '20px' }}
        w="100px"
      />
    </Flex>
  );
}

function HotSingers() {
  const emptyArtist = {
    artistId: -1,
    name: '',
    imageUrl: '',
  };

  const [artists, setArtists] = useState<Artist[]>([
    emptyArtist,
    emptyArtist,
    emptyArtist,
    emptyArtist,
    emptyArtist,
  ]);

  useEffect(() => {
    getAxiosInstance()
      .get('/api/artist/v1/recentlyTop')
      .then(({ data }) => {
        setArtists(data.response.result.artists);
      });
  }, []);

  return (
    <SectionLayout id="hotSingers" sectionTitle="熱門歌手">
      <BaseCarousel
        variant="middle"
        containerId="hotSingers"
        totalNum={artists.length}
      >
        {artists.map((artist, index) => {
          return artist.artistId === -1 ? (
            <LoadingPreivew key={index} />
          ) : (
            <CommonPreview
              key={index}
              title={artist.name}
              titleUrl={`/artist/${artist.artistId}`}
              imageUrl={artist.imageUrl}
              type="artist"
              picStyle={{ size: 'default', variant: 'circle' }}
            />
          );
        })}
      </BaseCarousel>
    </SectionLayout>
  );
}

export const HotSingersMemo = React.memo(HotSingers);
