import gql from 'graphql-tag';

const queryMagazinesPagination = gql`
  query MagazinePagination($num: Int, $cursor: String) {
    magazines(first: $num, after: $cursor) {
      totalCount
      edges {
        cursor
        node {
          magazineId
          title
          iconImageUrl
          startDate
        }
      }
    }
  }
`;

export default queryMagazinesPagination;
