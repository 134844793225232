import { Box } from '@chakra-ui/react';
import { useMoment } from '@/hooks/momentHooks';
import { MomentInfo } from '@/components/Page/MainPage/Moment/MomentInfo';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PrevArrowButton, NextArrowButton } from './MomentCarouselButton';
import React from 'react';

function Moment() {
  const { moment } = useMoment();

  return (
    <>
      <Box
        width={{ x3l: '964px' }}
        margin={{ x3l: '0 auto', lg: '0 43px' }}
        padding={{ x2l: '36px 0', lg: '32px 0', base: '18px 0' }}
      >
        <Slider
          nextArrow={<NextArrowButton />}
          prevArrow={<PrevArrowButton />}
          centerMode={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          centerPadding="6%"
          autoplay={moment.isLoaded}
          autoplaySpeed={5000}
        >
          {moment.data.map((m, index) => (
            <MomentInfo key={index} {...m} />
          ))}
        </Slider>
      </Box>
    </>
  );
}

export const MomentMemo = React.memo(Moment);
